import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-certified-agent-unavailable-modal',
  templateUrl: './certified-agent-unavailable-modal.component.html',
  styleUrls: ['./certified-agent-unavailable-modal.component.scss']
})
export class CertifiedAgentUnavailableModalComponent {
  constructor(public matDialogRef: MatDialogRef<CertifiedAgentUnavailableModalComponent>) {}
}
