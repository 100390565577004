import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  AgentCertified,
  CertifiedAgentModel,
  CertifiedAgentResponseErrorEnum
} from '@app/certified-agent/models/certified-agent.model';
import { CertifiedAgentService } from '@app/certified-agent/services/certified-agent.service';
import { AppState } from '@app/core/state';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { BrazilValidators } from '@shared/interfaces/validators/brazil-validators.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { SwalService } from '@shared/services/swal/swal.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { CertifiedAgentUnavailableModalComponent } from './../certified-agent-unavailable-modal/certified-agent-unavailable-modal.component';

@Component({
  selector: 'app-certified-agent-form',
  templateUrl: './certified-agent-form.component.html',
  styleUrls: ['./certified-agent-form.component.scss']
})
export class CertifiedAgentFormComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();

  public window = window;
  public newUserForm: FormGroup;
  type: any;

  readonly Insert: Tag = Tag.Insert;
  readonly Selecionar: Tag = Tag.Selecionar;

  @Input() screenType: string;

  @Output() back = new EventEmitter<any>();

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  public documentNumberMask: string;
  public saleId$: Observable<number> = this.store$.select('tabs').pipe(map(tab => +tab.selectedTab.code));

  initialDocument: any = null;
  public agenteCertificado: CertifiedAgentModel;
  public showMsgAgenteCertificado = false;

  constructor(
    private fb: FormBuilder,
    private internationalizationService: InternationalizationService,
    private modalService: NgbModal,
    private genTagger: GenTagger,
    private store$: Store<AppState>,
    private swalService: SwalService,
    private agentService: CertifiedAgentService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.createForm();

    this.newUserForm
      .get('documentNumber')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(([newValue]) => {
        if (newValue.length !== 11 && newValue === this.initialDocument) {
          this.newUserForm.get('documentNumber').setErrors({ valid: false });
        }

        this.agenteCertificado = null;
      });

    this.genTagger.setTag({
      event_category: this.category(),
      event_label: this.screenType === 'new-user' ? 'Novo usuário' : 'Editar usuário',
      event_action: `Abriu modal - ${this.screenType === 'new-user' ? 'Novo usuário' : 'Editar usuário'}`
    });
  }

  public category(): string {
    if (this.screenType === 'new-user') {
      return '/portallojista/gestaodeusuarios/novousuario';
    }
    if (this.screenType === 'update-user') {
      return '/portallojista/gestaodeusuarios/editarusuario';
    }
  }

  backManagementList() {
    this.back.emit({
      type: 'listagem'
    });
  }

  close(content) {
    this.modalService.dismissAll(content);
  }

  public searchAgent(): void {
    const payload = {
      documentNumber: this.newUserForm.get('documentNumber').value,
      certificationNumber: ''
    };

    this.agentService
      .getCertifiedAgents()
      .pipe(catchError(() => of([])))
      .pipe(
        switchMap(result => {
          if (result.some(item => +item.documentNumber === +payload.documentNumber)) {
            return throwError({
              error: {
                errorTemplateDetailCode: CertifiedAgentResponseErrorEnum.ALREADY_EXISTS
              }
            });
          }
          return this.agentService.getCertifiedAgent(payload);
        })
      )
      .subscribe(
        (agente: AgentCertified) => {
          this.agenteCertificado = agente.agentCertified[0];
        },
        error => {
          if (error.error?.errorTemplateDetailCode === CertifiedAgentResponseErrorEnum.EAC001) {
            this.getCertifiedAgentUnavailableModal();
          } else {
            const msg = this.getMessageErrorModal(error.error.errorTemplateDetailCode);
            this.swalService.createAlertWarning(msg.message);
          }
        }
      );
  }

  private getMessageErrorModal(error: CertifiedAgentResponseErrorEnum) {
    switch (error) {
      case CertifiedAgentResponseErrorEnum.EAC002:
        return {
          title: ' Tempo de consulta expirado',
          iconPath: './assets/images/ic_close_red.svg',
          message:
            'A consulta do CPF do agente demorou mais do que o esperado e foi interrompida. Por favor, verifique sua conexão de internet e tente novamente.',
          error: CertifiedAgentResponseErrorEnum.EAC002
        };

      case CertifiedAgentResponseErrorEnum.EAC003:
        return {
          title: 'CPF inválido',
          iconPath: './assets/images/ic_close_red.svg',
          message:
            'O CPF inserido não possui uma certificação válida. Por favor, revise os dados e tente novamente',
          error: CertifiedAgentResponseErrorEnum.EAC003
        };

      case CertifiedAgentResponseErrorEnum.EAC004:
        return {
          title: 'Ocorreu um erro inesperado',
          iconPath: './assets/images/ic_close_red.svg',
          message: 'O CPF inserido não possui uma certificação válida. Por favor, revise os dados e tente novamente',
          error: CertifiedAgentResponseErrorEnum.EAC004
        };

      case CertifiedAgentResponseErrorEnum.ALREADY_EXISTS:
        return {
          title: 'Este agente já está incluído',
          iconPath: './assets/images/ic_alert_warning.svg',
          message: 'O agente selecionado já está incluído na plataforma.',
          error: CertifiedAgentResponseErrorEnum.DEFAULT
        };

      default:
        return {
          title: 'Ocorreu um erro inesperado',
          iconPath: './assets/images/ic_close_red.svg',
          message: 'Por favor, tente novamente mais tarde',
          error: CertifiedAgentResponseErrorEnum.DEFAULT
        };
    }
  }

  getCertifiedAgentUnavailableModal() {
    this.dialog.open(CertifiedAgentUnavailableModalComponent, {
      maxWidth: '400px',
      disableClose: true
    });
  }

  public associateAgent(): void {
    if (this.newUserForm.invalid || !this.agenteCertificado) return;

    const payload = {
      agentCertifiedNumber: this.agenteCertificado.agentCertifiedNumber,
      certificationNumber: this.agenteCertificado.certificationNumber
    };

    this.agentService.updateAgentRelationship(payload).subscribe(
      (data: any) => {
        this.showMsgAgenteCertificado = true;
        this.agenteCertificado = null;
        this.newUserForm.reset();
      },
      error => {
        this.showMsgAgenteCertificado = false;
        const msg = error.error.messages[2].message;
        this.swalService.createAlertWarning(msg).then(data => {
          if (data.value) {
            this.backManagementList();
          }
        });
      }
    );
  }

  private createForm(): void {
    const validatorsBr = this.pioneerValidators as BrazilValidators;

    this.newUserForm = this.fb.group({
      documentNumber: new FormControl('', validatorsBr.documentHybrid)
    });
  }

  tag(category, action, label) {
    this.genTagger.setTag({
      event_action: `Selecionou - ${action}`,
      event_category: category,
      event_label: label
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
