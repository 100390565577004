import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import * as fromFormalization from '@app/core/state/formalization';
import * as fromProposal from '@app/core/state/proposal';
import {
  PendencyTypeCustomerEnum,
  PendencyTypeEnum,
  PendencyTypeFieldEnum
} from '@app/pendency/models/pendency-type.enum';
import { PendencyService } from '@app/pendency/pendency.service';
import { ChecklistItemTypeEnum } from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/api/query.checklist.model';
import {
  ChecklistModel,
  ChecklistStatusEnum
} from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/checklist.model';
import { ConfirmationData, ProposalParameters } from '@app/proposal-workflow/models/proposal-data.model';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { UpdatePendencyBpoPayload } from './pendency-update-bpo.type';

@Component({
  selector: 'app-pendency-update-bpo',
  templateUrl: './pendency-update-bpo.component.html',
  styleUrls: ['./pendency-update-bpo.component.scss']
})
export class PendencyUpdateBpoComponent implements OnInit, OnChanges, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() proposal: FormalizationProposalDTO;
  @Input() proposalInformation: ConfirmationData;
  @Input() checklist: ChecklistModel[] = [];
  @Input() pendencyType: PendencyTypeEnum;
  @Input() message: string;
  @Input() comboParams: ProposalParameters;
  @Input() nfValue: number;
  @Input() proposalPendencyBiometryFlowEnabled: boolean;

  @Output() updatePendencyValues: EventEmitter<UpdatePendencyBpoPayload> = new EventEmitter();
  @Output() done: EventEmitter<any> = new EventEmitter();

  public checklistItems: ChecklistModel[];
  public maxSizeAllowed = '10';
  public completedChecklistIds: string[] = [];
  public personalForm: FormGroup;
  public uploadsComplete = false;

  public isFieldsBpoUpdate = false;
  public bpoUpdateComplete = false;
  public codeFields: any;
  public nameCorporate = false;
  public companyType = false;
  public nameRepresentative = false;
  public nameClient = false;
  public personsType = SubmitPersonType;

  constructor(private store$: Store<AppState>, private pendencyService: PendencyService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ((this.checklist.length && changes.checklist) || (this.proposal && changes.proposal)) {
      this.handleProposalAndChecklist();
    }
  }

  ngOnInit(): void {
    this.setMaxFileAllowedSubscription();
    this.handleProposalAndChecklist();
  }

  private handleProposalAndChecklist(): void {
    if (this.pendencyType === PendencyTypeEnum.BPO_PENDENCY && this.proposal) {
      this.buildForm();

      this.checklistItems = this.checklist.filter(
        item =>
          (item.type && item.type.code && item.status === ChecklistStatusEnum.PENDING) ||
          (ChecklistStatusEnum.RELEASE_INTERNAL && item.type.code === ChecklistItemTypeEnum.UPLOAD)
      );
      this.resolvePendency();

      this.pendencyService
        .getPendencyByProposalId(this.proposal.id)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          filter(res => res.fields && res.fields.length > 0)
        )
        .subscribe(response => {
          this.isFieldsBpoUpdate = true;
          this.codeFields = [].concat(response.fields);
          this.addControl();
        });
    } else {
      this.isFieldsBpoUpdate = false;
    }
  }

  private buildForm(): void {
    this.personalForm = new FormGroup({});

    this.setFormSubscription();
  }

  private addControl(): void {
    this.codeFields.forEach(field => {
      if (
        field.code === PendencyTypeFieldEnum.RZSOCIAL &&
        this.proposalInformation.proposalData.client === this.personsType.PJ
      ) {
        this.nameCorporate = true;
        const nameCorporateControl = new FormControl(field.value, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(60),
          Validators.pattern(/^[a-zA-ZÀ-ÿ.0-9]{1,}[a-zA-ZÀ-ÿ.0-9]{1,}(?: [a-zA-ZÀ-ÿ0-9]+){1,}$/)
        ]);
        this.personalForm.addControl('nameCorporate', nameCorporateControl);
      }
      if (
        field.code === PendencyTypeFieldEnum.TPEMPRESA &&
        this.proposalInformation.proposalData.client === this.personsType.PJ
      ) {
        this.companyType = true;
        const companyTypeControl = new FormControl(field.value, Validators.required);
        this.personalForm.addControl('companyType', companyTypeControl);
      }
      if (
        field.code === PendencyTypeFieldEnum.NMREPRES &&
        this.proposalInformation.proposalData.client === this.personsType.PJ
      ) {
        this.nameRepresentative = true;
        const nameRepresentativeControl = new FormControl(field.value, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(60),
          Validators.pattern(/^[a-zA-ZÀ-ÿ]{2,}(?: [a-zA-ZÀ-ÿ]+){1,}$/)
        ]);
        this.personalForm.addControl('nameRepresentative', nameRepresentativeControl);
      }
      if (
        field.code === PendencyTypeFieldEnum.NMCLIENTE &&
        this.proposalInformation.proposalData.client === this.personsType.PF
      ) {
        this.nameClient = true;
        const nameControl = new FormControl(field.value, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(60),
          Validators.pattern(/^[a-zA-ZÀ-ÿ]{2,}(?: [a-zA-ZÀ-ÿ]+){1,}$/)
        ]);
        this.personalForm.addControl('name', nameControl);
      }
    });
    this.resolvePendency();
  }

  private setFormSubscription(): void {
    this.personalForm.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged(),
        debounceTime(300),
        filter(() => this.proposalPendencyBiometryFlowEnabled || this.personalForm.valid)
      )
      .subscribe(() => {
        if (!this.proposalPendencyBiometryFlowEnabled) {
          return this.submitUpdateRegister();
        }
        this.resolvePendency();
        this.updatePendencyValues.emit({
          pendencies: this.createUpdatePendencyPayload(),
          hasChanged: this.checkHasUpdatedPendencies()
        });
      });
  }

  private setMaxFileAllowedSubscription(): void {
    this.store$
      .select(fromFormalization.selectors.selectMaxFileSizeAllowed)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(maxSizeAllowed => (this.maxSizeAllowed = maxSizeAllowed));

    this.store$.dispatch(new fromFormalization.actions.GetMaxFileSizeAllowedAction());
  }

  public handleUploadFinished(obj: { id: string; completed: boolean }): void {
    // If upload is successful and the element is not in the list, add it.
    if (obj.completed) {
      if (this.completedChecklistIds.find(ele => ele === obj.id)) return;
      this.completedChecklistIds.push(obj.id);
    }

    // If deletion is successful and the element is in the list, remove it.
    if (!obj.completed) {
      if (!this.completedChecklistIds.find(ele => ele === obj.id)) return;
      const index = this.completedChecklistIds.findIndex(ele => ele === obj.id);
      this.completedChecklistIds.splice(index, 1);
    }

    // Compare checklist to successful uploads to determine if we need to enable
    // submit button.
    this.uploadsComplete = this.checklistItems.every(ele => this.completedChecklistIds.includes(ele.id));
    this.resolvePendency();
  }

  public submitUpdateRegister(): void {
    const payload = this.createUpdatePendencyPayload();

    this.pendencyService
      .updateBpoPendency(payload, this.proposal.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.bpoUpdateComplete = true;
        this.resolvePendency();
      });
  }

  public resolvePendency(): void {
    const finishedUploads = !this.checklistItems.length || this.uploadsComplete;
    const finishedBpoPendencies =
      !this.isFieldsBpoUpdate || (this.bpoUpdateComplete || this.proposalPendencyBiometryFlowEnabled);
    const formValid = !this.personalForm || this.personalForm.valid;
    const completed =
      ((finishedUploads && !finishedBpoPendencies) || (finishedUploads && finishedBpoPendencies)) && formValid;
    this.done.emit(completed);
  }

  private createUpdatePendencyPayload(): any {
    const formData = this.personalForm.getRawValue();
    const { nameCorporate, companyType, nameRepresentative, name } = formData;
    const fields = [];
    fields.push({ nameCorporate, companyType, nameRepresentative, name });

    const payload = [];
    fields.forEach(f => {
      if (f.name) {
        const fieldName = {
          customer: PendencyTypeCustomerEnum.CLI,
          code: PendencyTypeFieldEnum.NMCLIENTE,
          value: f.name
        };
        payload.push(fieldName);
      }
      if (f.nameRepresentative) {
        const fieldRepresentative = {
          customer: PendencyTypeCustomerEnum.RPP,
          code: PendencyTypeFieldEnum.NMREPRES,
          value: f.nameRepresentative
        };
        payload.push(fieldRepresentative);
      }
      if (f.nameCorporate) {
        const fieldCorporate = {
          customer: PendencyTypeCustomerEnum.CLI,
          code: PendencyTypeFieldEnum.RZSOCIAL,
          value: f.nameCorporate
        };
        payload.push(fieldCorporate);
      }
      if (f.companyType) {
        const fieldCompanyType = {
          customer: PendencyTypeCustomerEnum.CLI,
          code: PendencyTypeFieldEnum.TPEMPRESA,
          value: f.companyType
        };
        payload.push(fieldCompanyType);
      }
    });
    return payload;
  }

  private checkHasUpdatedPendencies(): boolean {
    const isClientPF = this.proposalInformation.proposalData.client === this.personsType.PF;
    let hasChangedBpoPendencies = false;
    for (let i = 0; i < this.codeFields.length; i++) {
      if (hasChangedBpoPendencies) break;
      const pendency = this.codeFields[i];
      let input: AbstractControl;
      if (isClientPF) {
        if (pendency.code === PendencyTypeFieldEnum.NMCLIENTE) {
          input = this.personalForm.get('name');
        }
      } else {
        if (pendency.code === PendencyTypeFieldEnum.NMREPRES) {
          input = this.personalForm.get('nameRepresentative');
        }
        if (pendency.code === PendencyTypeFieldEnum.RZSOCIAL) {
          input = this.personalForm.get('nameCorporate');
        }
        if (pendency.code === PendencyTypeFieldEnum.TPEMPRESA) {
          input = this.personalForm.get('companyType');
        }
      }
      hasChangedBpoPendencies =
        input.value && (!pendency.value || input.value.toLowerCase() !== pendency.value.toLowerCase());
    }
    return hasChangedBpoPendencies;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.store$.dispatch(new fromProposal.actions.ClearChecklistAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalStageAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalIdAction());
  }
}
