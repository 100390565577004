<ng-container *ngIf="!isMobile">
  <div class="financing-box" *ngIf="!showStoreConditions">
    <img src="./assets/images/financiamento-mais-vezes.svg" alt="" />

    <p class="financing-text">{{ 'STEP-REGISTER-SIDEBAR-TEXT' | translate }}</p>

    <p class="alternate-img" (click)="toggleStoreConditionsVisibility()">
      <img src="./assets/images/ic_file_transfer.svg" class="mr-2" />
      {{ 'STEP-REGISTER-SIDEBAR-BACK-LINK' | translate }}
    </p>
  </div>

  <div class="summary-card mb-5" *ngIf="showStoreConditions">
    <div class="summary-title d-flex justify-content-between align-items-center">
      <p class="details">
        <span
          class="alert-point blue"
          (click)="blueModal = !blueModal"
          *ngIf="proposalData?.calculationLimit?.bannerShowFlag"
        ></span>
        {{ 'PROPOSAL-STORE-CONDITIONS-TITLE' | translate }}
      </p>
      <img
        src="./assets/images/ic_file_transfer.svg"
        class="alternate-img"
        (click)="toggleStoreConditionsVisibility()"
      />
    </div>

    <div class="sidebar-box mt-1 mb-1 position-relative" *ngIf="hasOffersData">
      <!-- Moral Laranja -->
      <div class="alert-sidebar orange" *ngIf="orangeModal">
        <header class="d-flex justify-content-between mb-4">
          <mat-icon aria-hidden="false" id="info">info_outline</mat-icon>
          <mat-icon aria-hidden="false" (click)="closeOrangeModal()">close</mat-icon>
        </header>
        <p class="font-weight-bold">Sua entrada precisou ser alterada</p>
        <p>
          Essa operação para o seu cliente precisa de pelo menos
          <span class="font-weight-bold entryValue">{{ proposalData?.offersData?.entryValue | localeCurrency }}</span>
          de entrada para que o financiamento seja possível.
        </p>
        <p>A entrada foi ajustada automaticamente para você.</p>
      </div>
      <!--/ Fim Modal Laranja -->

      <!-- Modal Azul -->
      <div class="alert-sidebar blue" *ngIf="blueModal && !orangeModal">
        <header class="d-flex justify-content-between mb-4">
          <img aria-hidden="false" src="./assets/santander/images/ic_bank_credit.svg" id="info" class="bank-credit" />
          <mat-icon aria-hidden="false" (click)="blueModal = !blueModal">close</mat-icon>
        </header>
        <p class="font-weight-bold">Melhores condições para seu cliente</p>
        <p>
          Este {{ documentType.name }} possui um limite de
          <span class="font-weight-bold">{{ proposalData?.calculationLimit?.maxBannerValue | localeCurrency }}</span>
          pré-aprovado. Edite o valor e simule novamente a operação para aumentar o valor do financiamento.
        </p>
      </div>
      <!--/ Fim Modal Azul -->

      <!-- Modal quanto vou receber -->
      <div class="alert-sidebar white" *ngIf="whiteModal">
        <header class="d-flex justify-content-between mb-4">
          <img src="./assets/images/ic_percent.png" />
          <mat-icon aria-hidden="false" (click)="whiteModal = !whiteModal">close</mat-icon>
        </header>
        <ng-container *ngIf="showEntryFields">
          <p>
            Como solicitou entrada, o % de retenção sobre o valor total da venda ficou menor
          </p>
          <p>
            Percentual de retenção sobre o valor total da <strong>venda</strong><br />
            <span class="percentual-compra">{{
              proposalData?.offersData?.shopkeeperData?.retentionPercentageByPurchaseValue | localePercentage
            }}</span>
            <sup
              >{{
                proposalData?.offersData?.shopkeeperData?.retentionPercentageByReleasedAmount | localePercentage
              }}*</sup
            >
          </p>
          <p>
            Valor liberado:
            <br />
            {{ proposalData?.offersData?.shopkeeperData?.releasedAmount | localeCurrency }} +
            {{ proposalData.offersData.entryValue | localeCurrency }} (Entrada)
          </p>
        </ng-container>
        <p>
          *Percentual de retenção sobre o valor <strong>parcelado</strong>
          <span class="percentual-liberado">{{
            proposalData?.offersData?.shopkeeperData?.retentionPercentageByReleasedAmount | localePercentage
          }}</span>
        </p>
      </div>
      <!--/ Fim Modal quanto vou receber -->

      <!-- FormGroup -->
      <ng-container [formGroup]="valuesForm">
        <div class="sidebar-div">
          <p class="sidebar-text">Valor da compra</p>
          <p class="sidebar-info position-relative" *ngIf="!editValues">
            <span
              class="alert-point blue"
              (click)="blueModal = !blueModal"
              *ngIf="hasOffersData && proposalData?.offersData?.shopkeeperData?.isPossibleToIncreaseFinancedValue"
            >
            </span>
          </p>
          <p class="sidebar-info" *ngIf="!editValues">{{ proposalData.offersData.purchaseValue | localeCurrency }}</p>
          <mat-form-field *ngIf="editValues">
            <input
              class="text-left input-value"
              matInput
              currencyMask
              formControlName="purchaseValue"
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
              maxlength="11"
            />
            <mat-error *ngIf="purchaseValue?.errors?.min">
              Valor mínimo é de {{ minPurchaseValue | localeCurrency }}
            </mat-error>
            <button (click)="cleanPurchaseValue()" mat-button matSuffix mat-icon-button aria-label="Clear">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="sidebar-div">
          <p class="sidebar-text">Entrada</p>
          <p class="sidebar-info position-relative" *ngIf="!editValues">
            <span
              class="alert-point orange"
              (click)="orangeModal = !orangeModal"
              *ngIf="hasOffersData && proposalData?.offersData?.shopkeeperData?.isEntryValueAjusted"
            >
            </span>
            {{ proposalData.offersData.entryValue | localeCurrency }}
          </p>
          <mat-form-field *ngIf="editValues">
            <input
              class="text-left input-value"
              matInput
              currencyMask
              formControlName="entryValue"
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
              maxlength="11"
            />
            <button (click)="cleanEntryValue()" mat-button matSuffix mat-icon-button aria-label="Clear">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div class="sidebar-div">
          <p class="sidebar-text" *ngIf="proposalData.productCode === productCode.CSC">Valor cedido</p>
          <p class="sidebar-text" *ngIf="proposalData.productCode === productCode.CSCP">Valor cedido</p>
          <p class="sidebar-text" *ngIf="proposalData.productCode === productCode.CDC">Valor financiado</p>
          <p class="sidebar-info big-value">{{ proposalData.offersData.financedValue | localeCurrency }}</p>
          <mat-form-field *ngIf="editValues" [hidden]="true">
            <!-- <input type="hidden" formControlName="financedValue" /> -->
            <input
              class="text-left input-value"
              matInput
              currencyMask
              formControlName="financedValue"
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
              maxlength="11"
            />
            <mat-error *ngIf="financedValue?.errors?.min">
              Valor mínimo financiado é de
              {{ proposalData?.offersData?.shopkeeperData?.minFinancedValue | localeCurrency }}
            </mat-error>
            <mat-error *ngIf="financedValue?.errors?.max">
              Valor máximo financiado é de
              {{ proposalData?.offersData?.shopkeeperData?.maxFinancedValue | localeCurrency }}
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>
      <!-- / FormGroup -->

      <div class="sidebar-div" *ngIf="proposalData?.offersData?.shopkeeperData?.showReleasedAmount">
        <div class="d-flex">
          <!-- <pion-tooltip></pion-tooltip> -->
          <mat-icon class="pointer" (click)="whiteModal = !whiteModal">info_outline</mat-icon>
          <p class="sidebar-info small-value pointer" (click)="whiteModal = !whiteModal">Quanto vou receber?</p>
        </div>
      </div>

      <div class="sidebar-div">
        <button
          mat-stroked-button
          color="primary"
          class="w-100 edit-button"
          (click)="changeValues()"
          appTagger
          [event_category]="category"
          event_label="Editar"
          *ngIf="!editValues"
        >
          Editar
        </button>
        <button
          mat-stroked-button
          color="primary"
          class="w-100 edit-button"
          (click)="changeValues()"
          *ngIf="editValues"
          [disabled]="!valuesForm.valid"
        >
          Salvar alterações
        </button>
      </div>

      <!-- Subsidiary Value -->
      <div class="sidebar-div" *ngIf="withSubsidy">
        <app-subsidiary-value
          [subsidyData]="subsidyData"
          [proposalData]="proposalData"
          (subsidyValueChanged)="subsidyValueChanged($event)"
        >
        </app-subsidiary-value>
      </div>
      <!-- / Subsidiary Value -->

      <hr class="mt-5 mb-4" *ngIf="proposalData?.offersData?.shopkeeperData?.showReleasedAmount" />

      <div class="sidebar-div" *ngIf="proposalData?.offersData?.shopkeeperData?.showReleasedAmount">
        <p class="sidebar-text">Valor liberado</p>
        <p class="sidebar-info big-value">
          {{ proposalData?.offersData?.shopkeeperData?.releasedAmount | localeCurrency }}
        </p>
      </div>
    </div>

    <hr />
    <div class="sidebar-box mt-1 mb-1" *ngIf="couponData">
      <div class="sidebar-div">
        <app-discount-coupon [couponData]="couponData" [category]="category"></app-discount-coupon>
      </div>
    </div>
    <ng-container *ngIf="isEnabledToInformIncome">
      <hr />
      <div class="sidebar-box income">
        <div
          class="d-flex income"
          *ngIf="
            statusProposal?.IsUpdateMaxFinancedValue === 'false' || statusProposal?.IsUpdateMaxFinancedValue === ''
          "
        >
          <img src="./assets/ic_circle_information.svg" alt="#" />
          <div>
            <p>
              <a
                class="monthlyIncome"
                (click)="opendialog()"
                appTagger
                [event_category]="category"
                event_label="Informe sua renda"
                >Informe sua renda</a
              >
              para rever o limite pré-aprovado
            </p>
          </div>
        </div>
        <p *ngIf="statusProposal?.IsUpdateMaxFinancedValue === 'true'">
          <a class="monthlyIncome" (click)="removeCredit()">Remover renda</a>
          informada
        </p>
      </div>
    </ng-container>
  </div>

  <div class="summary-card mb-5" *ngIf="hasPersonalData && showStoreConditions">
    <div class="summary-title">
      <p class="details">{{ 'PROPOSAL-CONDITIONS-RESUME' | translate }}</p>
    </div>

    <div class="sidebar-box">
      <div class="sidebar-div">
        <p class="sidebar-text">{{ documentType.name }}</p>
        <p class="sidebar-info">{{ proposalData?.personalData?.documentNumber | documentNumber }}</p>
      </div>

      <div class="sidebar-div">
        <p class="sidebar-text">{{ documentType.birthDate }}</p>
        <p class="sidebar-info">{{ proposalData?.personalData?.birthDate | dateFormatPipe }}</p>
      </div>

      <div class="sidebar-div">
        <p class="sidebar-text">{{ 'STEP-CONFIRMATION-PHONE' | translate }}</p>
        <p class="sidebar-info">{{ proposalData.personalData?.phone?.completPhone | maskPhone }}</p>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isMobile && (currentUrl === '/proposal/step-offer' || currentUrl === '/proposal/step-simulation')">
  <div class="bottom-sheet in" [ngClass]="{ in: in, out: show }">
    <div
      class="bottom-sheet-header"
      (click)="handleBottomSheet()"
      appTagger
      [event_category]="category"
      [event_label]="show ? 'ocultar resumo' : 'mostrar resumo'"
    >
      <div class="header-summary">
        <mat-icon class="icon-arrow" *ngIf="!show">keyboard_arrow_up</mat-icon>
        <mat-icon class="icon-arrow" *ngIf="show">keyboard_arrow_down</mat-icon>

        <div *ngIf="currentUrl === '/proposal/step-offer'">
          <div class="summary">
            <p class="text-cdc" *ngIf="proposalData?.productCode === productCode.CDC">
              CDC
            </p>
            <p class="text-csc" *ngIf="proposalData?.productCode === productCode.CSC">
              CSC
            </p>
            <p class="text-csc" *ngIf="proposalData?.productCode === productCode.CSCP">
              CSC P
            </p>
            <p class="summary-text" *ngIf="hasOffersData">
              {{ 'Summary' | translate }}
              <span
                class="alert-point blue"
                (click)="openBlueModal($event)"
                *ngIf="proposalData?.calculationLimit?.bannerShowFlag"
              >
              </span>
            </p>
          </div>
        </div>
        <div class="sidebar-mobile-title" *ngIf="currentUrl === '/proposal/step-simulation'">
          <div class="sidebar-mobile-title">
            <p>
              {{ 'Summary' | translate }}
              <span
                class="alert-point-simulation"
                (click)="openBlueModal($event)"
                *ngIf="proposalData?.calculationLimit?.bannerShowFlag"
              >
              </span>
            </p>
            <span *ngIf="hasOffersData">
              <span class="sidebar-mobile-title-installments">{{ proposalData?.installmentAmount }}x</span>
              <span *ngIf="proposalData?.planType === paymentPlanType.FLT_PLAN">
                {{ proposalData?.installmentValue | localeCurrency }}</span
              >
              <span *ngIf="proposalData?.planType !== paymentPlanType.FLT_PLAN">
                {{ 'SIMULATION-SUMARY-CUSTOM-PLAN' | translate }}</span
              >
            </span>
          </div>
        </div>
      </div>
      <div
        class="header-values"
        [ngClass]="{ hide: hideTransition }"
        *ngIf="!showInitialFee && !show && hasOffersData && currentUrl === '/proposal/step-offer'"
      >
        <p class="sidebar-text">Valor da compra</p>
        <p class="value">
          <strong> {{ proposalData.offersData.purchaseValue | localeCurrency }}</strong>
        </p>
      </div>
    </div>
    <div class="alert-sidebar-container">
      <!-- Modal Azul Mobile -->
      <div class="alert-sidebar blue" *ngIf="blueModal">
        <header class="d-flex justify-content-between mb-4">
          <img aria-hidden="false" src="./assets/santander/images/ic_bank_credit.svg" id="info" class="bank-credit" />
          <mat-icon aria-hidden="false" (click)="blueModal = !blueModal">close</mat-icon>
        </header>
        <p class="font-weight-bold">Melhores condições para seu cliente</p>
        <p>
          Este {{ documentType.name }} possui um limite de
          <span class="font-weight-bold">{{ proposalData?.calculationLimit?.maxBannerValue | localeCurrency }}</span>
          pré-aprovado. Edite o valor e simule novamente a operação para aumentar o valor do financiamento.
        </p>
      </div>
      <!--/ Fim Modal Azul -->

      <!-- Modal Branco Mobile - Quanto vou receber? -->
      <div class="alert-sidebar white" *ngIf="whiteModal">
        <header class="d-flex justify-content-between mb-4">
          <img src="./assets/images/ic_percent.png" />
          <mat-icon aria-hidden="false" (click)="whiteModal = !whiteModal">close</mat-icon>
        </header>
        <ng-container *ngIf="showEntryFields">
          <p>
            Como solicitou entrada, o % de retenção sobre o valor total da venda ficou menor
          </p>
          <p>
            Percentual de retenção sobre o valor total da <strong>venda</strong><br />
            <span class="percentual-compra">{{
              proposalData?.offersData?.shopkeeperData?.retentionPercentageByPurchaseValue | localePercentage
            }}</span>
            <sup
              >{{
                proposalData?.offersData?.shopkeeperData?.retentionPercentageByReleasedAmount | localePercentage
              }}*</sup
            >
          </p>
          <p>
            Valor liberado:
            <br />
            {{ proposalData?.offersData?.shopkeeperData?.releasedAmount | localeCurrency }} +
            {{ proposalData.offersData.entryValue | localeCurrency }} (Entrada)
          </p>
        </ng-container>
        <p>
          *Percentual de retenção sobre o valor <strong>parcelado</strong>
          <span class="percentual-liberado">{{
            proposalData?.offersData?.shopkeeperData?.retentionPercentageByReleasedAmount | localePercentage
          }}</span>
        </p>
      </div>
      <!--/ Fim Modal quanto vou receber -->

      <!-- Moral Laranja Mobile -->
      <div [ngClass]="blueModal && orangeModal ? 'overlay' : ''" class="alert-sidebar orange" *ngIf="orangeModal">
        <header class="d-flex justify-content-between mb-4">
          <mat-icon aria-hidden="false" id="info">info_outline</mat-icon>
          <mat-icon
            aria-hidden="false"
            (click)="closeOrangeModal()"
            appTagger
            [event_category]="category"
            event_label="fechou modal - Sua entrada precisou ser alterada"
            >close</mat-icon
          >
        </header>
        <p class="font-weight-bold">Sua entrada precisou ser alterada</p>
        <p>
          Essa operação para o seu cliente precisa de pelo menos
          <span class="font-weight-bold">{{
            proposalData?.offersData?.shopkeeperData?.minEntryValue | localeCurrency
          }}</span>
          de entrada para que o financiamento seja possível.
        </p>
        <p>A entrada foi ajustada automaticamente para você.</p>
      </div>
      <!--/ Fim Modal Laranja -->
    </div>
    <div
      class="store-conditions offers"
      *ngIf="(currentUrl === '/proposal/step-offer' || currentUrl === '/proposal/step-simulation') && hasOffersData"
    >
      <div class="title title-mobile">
        <p *ngIf="proposalData?.productCode === productCode.CDC">
          {{ 'NEW-PROPOSAL' | translate }}
          <span class="text-cdc">
            CDC
          </span>
        </p>
        <p *ngIf="proposalData?.productCode === productCode.CSC">
          {{ 'NEW-PROPOSAL' | translate }}
          <span class="text-csc">
            CSC
          </span>
        </p>
        <p *ngIf="proposalData?.productCode === productCode.CSCP">
          {{ 'NEW-PROPOSAL' | translate }}
          <span class="text-cscp">
            CSC P
          </span>
        </p>
      </div>

      <div class="title title-mobile">
        <p>Condições da loja</p>
      </div>

      <div class="conditions-values">
        <div class="info">
          <!-- FormGroup -->
          <ng-container [formGroup]="valuesForm">
            <div class="sidebar-div">
              <p class="sidebar-text">Valor da compra</p>
              <p class="sidebar-info" *ngIf="!editValues">
                {{ proposalData.offersData.purchaseValue | localeCurrency }}
              </p>
              <mat-form-field *ngIf="editValues">
                <input
                  class="text-left input-value"
                  matInput
                  currencyMask
                  formControlName="purchaseValue"
                  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
                  maxlength="11"
                />
                <mat-error *ngIf="purchaseValue?.errors?.min">
                  Valor mínimo é de {{ minPurchaseValue | localeCurrency }}
                </mat-error>
                <button (click)="cleanPurchaseValue()" mat-button matSuffix mat-icon-button aria-label="Clear">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="sidebar-div">
              <p class="sidebar-text">Entrada</p>
              <p class="sidebar-info position-relative" *ngIf="!editValues">
                <span
                  class="alert-point orange"
                  (click)="orangeModal = !orangeModal"
                  *ngIf="hasOffersData && proposalData?.offersData?.shopkeeperData?.isEntryValueAjusted"
                >
                </span>
                {{ proposalData.offersData.entryValue | localeCurrency }}
              </p>
              <mat-form-field *ngIf="editValues">
                <input
                  class="text-left input-value"
                  matInput
                  currencyMask
                  formControlName="entryValue"
                  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
                  maxlength="11"
                />
                <mat-error *ngIf="entryValue?.errors?.min"> O valor mínimo é de {{ minEntryValue }} </mat-error>
                <button (click)="cleanEntryValue()" mat-button matSuffix mat-icon-button aria-label="Clear">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="sidebar-div">
              <p class="sidebar-text" *ngIf="proposalData.productCode === productCode.CDC">Valor financiado</p>
              <p class="sidebar-text" *ngIf="proposalData.productCode === productCode.CSC">Valor cedido</p>
              <p class="sidebar-text" *ngIf="proposalData.productCode === productCode.CSCP">Valor cedido</p>
              <p class="sidebar-info big-value">{{ proposalData.offersData.financedValue | localeCurrency }}</p>
              <mat-form-field *ngIf="editValues" [hidden]="true">
                <!-- <input type="hidden" formControlName="financedValue" /> -->
                <input
                  class="text-left input-value"
                  matInput
                  currencyMask
                  formControlName="financedValue"
                  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
                  maxlength="11"
                />
                <mat-error *ngIf="financedValue?.errors?.min">
                  Valor mínimo financiado é de
                  {{ proposalData?.offersData?.shopkeeperData?.minFinancedValue | localeCurrency }}
                </mat-error>
                <mat-error *ngIf="financedValue?.errors?.max">
                  Valor máximo financiado é de
                  {{ proposalData?.offersData?.shopkeeperData?.maxFinancedValue | localeCurrency }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="sidebar-div" *ngIf="proposalData?.offersData?.shopkeeperData?.showReleasedAmount">
              <div class="d-flex">
                <mat-icon class="pointer" (click)="whiteModal = !whiteModal">info_outline</mat-icon>
                <p class="sidebar-info small-value pointer" (click)="whiteModal = !whiteModal">Quanto vou receber?</p>
              </div>
            </div>
          </ng-container>
          <!-- / FormGroup -->
        </div>

        <div class="sidebar-div pb-2">
          <button
            mat-stroked-button
            color="primary"
            class="w-100 edit-button"
            (click)="changeValues()"
            *ngIf="!editValues"
          >
            Editar
          </button>
          <button
            mat-stroked-button
            color="primary"
            class="w-100 edit-button"
            (click)="changeValues()"
            *ngIf="editValues"
            [disabled]="!valuesForm.valid || !proposalData.offersData.financedValue > 0"
          >
            Salvar alterações
          </button>
        </div>

        <!-- Subsidiary Value -->
        <div class="sidebar-div" *ngIf="withSubsidy">
          <app-subsidiary-value [subsidyData]="subsidyData" (subsidyValueChanged)="subsidyValueChanged($event)">
          </app-subsidiary-value>
        </div>
        <!-- / Subsidiary Value -->

        <div class="sidebar-div" *ngIf="proposalData?.offersData?.shopkeeperData?.showReleasedAmount">
          <hr />
          <p class="sidebar-text">Valor liberado</p>
          <p class="sidebar-info big-value">
            {{ proposalData?.offersData?.shopkeeperData?.releasedAmount | localeCurrency }}
          </p>
        </div>

        <div class="mt-1 mb-1" *ngIf="couponData">
          <hr />
          <div class="sidebar-div">
            <app-discount-coupon [couponData]="couponData"></app-discount-coupon>
          </div>
        </div>

        <ng-container *ngIf="isEnabledToInformIncome">
          <hr />
          <div class="sidebar-box income px-0">
            <div
              class="d-flex income"
              *ngIf="
                statusProposal?.IsUpdateMaxFinancedValue === 'false' || statusProposal?.IsUpdateMaxFinancedValue === ''
              "
            >
              <img src="./assets/ic_circle_information.svg" alt="#" />
              <div>
                <p>
                  <a
                    class="monthlyIncome"
                    (click)="opendialog()"
                    appTagger
                    [event_category]="category"
                    event_label="Informe sua renda"
                    >Informe sua renda</a
                  >
                  para rever o limite pré-aprovado
                </p>
              </div>
            </div>
            <p *ngIf="statusProposal?.IsUpdateMaxFinancedValue === 'true'">
              <a class="monthlyIncome" (click)="removeCredit()">Remover renda</a>
              informada
            </p>
          </div>
        </ng-container>

        <div *ngIf="hasPersonalData && showStoreConditions">
          <hr />
          <div class="sidebar-div">
            <p class="sidebar-text">{{ documentType.name }}</p>
            <p class="sidebar-info">{{ proposalData?.personalData?.documentNumber | documentNumber }}</p>
          </div>

          <div class="sidebar-div">
            <p class="sidebar-text">{{ documentType.birthDate }}</p>
            <p class="sidebar-info">{{ proposalData?.personalData?.birthDate | dateFormatPipe }}</p>
          </div>

          <div class="sidebar-div">
            <p class="sidebar-text">{{ 'STEP-CONFIRMATION-PHONE' | translate }}</p>
            <p class="sidebar-info">{{ proposalData.personalData?.phone?.completPhone | maskPhone }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
