import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CertifiedAgentComponent } from './certified-agent.component';
import { CertifiedAgentCardComponent } from './components/certified-agent-card/certified-agent-card.component';
import { CertifiedAgentFormComponent } from './components/certified-agent-form/certified-agent-form.component';
import { CertifiedAgentListComponent } from './components/certified-agent-list/certified-agent-list.component';
import { CertifiedAgentUnavailableModalComponent } from './components/certified-agent-unavailable-modal/certified-agent-unavailable-modal.component';

@NgModule({
  declarations: [
    CertifiedAgentComponent,
    CertifiedAgentListComponent,
    CertifiedAgentFormComponent,
    CertifiedAgentCardComponent,
    CertifiedAgentUnavailableModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatCardModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatTooltipModule,
    NgbModule,
    CurrencyMaskModule
  ],
  exports: [CertifiedAgentComponent]
})
export class CertifiedAgentModule {}
