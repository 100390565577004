import { Component, EventEmitter, OnDestroy, Output, Renderer2 } from '@angular/core';
import {
  BiometryService,
  BiometryStageEnum
} from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/services/biometry.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { environment } from '@env/environment';
import { UnicoPathFileService } from '@shared/services/unico/unico-path-file.service';
import { UnicoService } from '@shared/services/unico/unico.service';
import { SelfieCameraTypes, UnicoCheckBuilder, UnicoConfig, UnicoThemeBuilder } from 'unico-webframe';
import { CameraScriptsPath } from './models/camera-scripts-paths';
import { BiometryErrorEnum, BiometryManagerService } from './services/biometry-manager.service';

declare const JSZip: any;
declare const SNXStegger: any;

@Component({
  selector: 'app-biometry',
  templateUrl: './biometry.component.html',
  styleUrls: ['./biometry.component.scss']
})
export class BiometryComponent implements OnDestroy {
  @Output() base64Content: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() goBack: EventEmitter<any> = new EventEmitter<any>();
  showCustonMessage = false;
  unicoBundle: UnicoConfig;
  // correct configuration for push/deploy
  modelsPath = this.getHostUrlBase('assets/models');
  resourcesPath = this.getHostUrlBase('assets/resources');
  // path = this.getHostUrlBase('originacao-loj/assets/models');
  // correct configuration for local development,
  // path = this.getHostUrlBase('assets/models');

  // Tipo 2: Camera Inteligente
  configurations = {
    TYPE: 2
  };

  layout = {
    silhouette: {
      primaryColor: '#0bbd26',
      secondaryColor: '#bd0b0b',
      neutralColor: '#fff'
    },
    buttonCapture: {
      backgroundColor: '#2980ff',
      iconColor: '#fff'
    },
    popupLoadingHtml:
      // eslint-disable-next-line max-len
      '<div style="position: absolute; top: 45%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center;">Loading...</div>',
    boxMessage: {
      backgroundColor: '#2980ff',
      fontColor: '#fff'
    },
    boxDocument: {
      backgroundColor: '#2980ff',
      fontColor: '#fff'
    }
  };

  callback = {
    on: {
      success: obj => {
        this.onSuccessCapture(obj);
      },
      error: error => {
        this.onFailedCapture(error);
      },
      support: error => {
        this.onBrowserNotSupport(error);
      }
    }
  };

  get basePathUnico(): string {
    return `${environment.path}/assets/unico`;
  }

  constructor(
    private renderer: Renderer2,
    private biometryManagerService: BiometryManagerService,
    private unicoPathFileService: UnicoPathFileService,
    public biometryService: BiometryService,
    public taggerService: TaggerService,
    public genTagger: GenTagger,
    public unicoService: UnicoService
  ) {
    const category = `/criarproposta/${this.taggerService.getProposalType()}/formalizacao/biometria-captura`;
    this.genTagger.setTag({
      event_category: category,
      event_action: Tag.pgView,
      event_label: 'Vamos la, prepare-se para selfie'
    });
    this.biometryManagerService.addJsToElement(CameraScriptsPath.PLATFORM, 'platform', this.renderer).onload = () => {
      this.biometryManagerService.addJsToElement(
        CameraScriptsPath.ADAPTER_LATEST,
        'adapter_latest',
        this.renderer
      ).onload = () => {
        this.biometryManagerService.addJsToElement(CameraScriptsPath.JSZIP, 'jszip', this.renderer).onload = () => {
          this.biometryManagerService.addJsToElement(
            CameraScriptsPath.STEGGER,
            'stegger',
            this.renderer
          ).onload = () => {
            this.getBundleJSON();
          };
        };
      };
    };
  }
  private getBundleJSON() {
    this.unicoPathFileService.getBundle().subscribe(res => {
      if (!!res.bundle) {
        const bundle = JSON.parse(res.bundle);
        this.unicoBundle = new UnicoConfig()
          .setProjectNumber(bundle.project_info.project_number)
          .setProjectId(bundle.project_info.project_id)
          .setMobileSdkAppId(bundle.client_info.mobilesdk_app_id)
          .setHostname(bundle.client_info.js_client_info.hostname)
          .setHostInfo(bundle.host_info.host_info)
          .setHostKey(bundle.host_info.host_key);
        this.setTypeCamera(2);
      }
    });
  }

  showCompletedAnimation() {
    this.showCustonMessage = false;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('box--completed')!.style.display = 'inline-block';
  }

  showLoadingModels() {
    this.showCustonMessage = false;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  }
  hideLoadingModels() {
    this.showCustonMessage = false;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('box--loading-models')!.style.display = 'none';
  }

  showError(message = 'Ops... Algo inesperado aconteceu!') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('error-message')!.innerHTML = message;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('box--error')!.style.display = 'inline-block';
  }

  getHostUrlBase(path) {
    if (window.location.href.search('tmp') > 0)
      return window.location.protocol + '//' + window.location.host + '/originacao-loj-tmp/' + path;

    if (window.location.host !== 'localhost:4200')
      return window.location.protocol + '//' + window.location.host + '/originacao-loj/' + path;

    return window.location.protocol + '//' + window.location.host + '/' + path;
  }

  getUnicoTheme() {
    const unicoTheme = new UnicoThemeBuilder()
      .setColorSilhouetteSuccess('#0bbd26')
      .setColorSilhouetteError('#bd0b0b')
      .setColorSilhouetteNeutral('#fff')
      .setBackgroundColor('#c00')
      .setColorText('#fff')
      .setBackgroundColorComponents('#c00')
      .setColorTextComponents('#fff')
      .setBackgroundColorButtons('#c00')
      .setColorTextButtons('#fff')
      .setBackgroundColorBoxMessage('#c00')
      .setColorTextBoxMessage('#fff')
      .setHtmlPopupLoading(
        `<div style="position: absolute; top: 45%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center;">Loading...</div>`
      )
      .build();

    return unicoTheme;
  }

  getLocationServiceFile() {
    return `${this.basePathUnico}/${this.unicoPathFileService.getFileName()}`;
  }

  setTypeCamera(_type: number) {
    this.showCustonMessage = false;

    this.showLoadingModels();

    const unicoCameraBuilder = new UnicoCheckBuilder();

    unicoCameraBuilder.setModelsPath(this.modelsPath);
    unicoCameraBuilder.setResourceDirectory(this.resourcesPath);

    unicoCameraBuilder.setTheme(this.getUnicoTheme());

    const unicoCamera = unicoCameraBuilder.build();

    const cameraPromised = unicoCamera.prepareSelfieCamera(this.unicoBundle, SelfieCameraTypes.SMART);

    cameraPromised.then((cameraOpener: any) => cameraOpener.open(this.callback));

    // acessoWebFrame.webFrameModel
    //   .loadModelsCameraInteligence(this.path)
    //   .then(() => {
    //     acessoWebFrame.initCamera(this.configurations, this.callback, this.layout);
    //   })
    //   .catch((e: string | undefined) => {
    //     this.showError(e);
    //     console.error(e);
    //   });
  }

  onSuccessCapture(obj: any) {
    this.showCustonMessage = false;

    const iv = SNXStegger.encodeIV(this.biometryManagerService.getIvParam());
    const stegger = new SNXStegger(iv);
    const base64result = obj.base64 ? obj.base64 : this.showError();
    const encrypted = obj.encrypted ? obj.encrypted : this.showError();
    this.showCompletedAnimation();

    try {
      const payload = stegger.preparePayload(
        JSON.stringify({
          iv,
          nonce: this.biometryManagerService.getNonceParam()
        })
      );

      stegger
        .prepareImages([base64result])
        .then(frames => {
          stegger
            .embed(frames, payload)
            .then(steggedFrames => {
              // eslint-disable-next-line
              this.biometryManagerService.successOnBiometry({ images: steggedFrames[0], encrypted });
              // eslint-disable-next-line
              this.base64Content.emit({ images: steggedFrames[0], encrypted });
            })
            .catch(e => {
              console.log('Stegger', 'Embed Exception', e);
            });
        })
        .catch(e => {
          console.log('Stegger', 'Prepare Image Exception', e);
        });
    } catch (ex) {
      this.biometryManagerService.log('An exception on stegger: ', ex);
      this.biometryManagerService.errorOnBiometry(BiometryErrorEnum.STEGGER);
      this.showError();
      return;
    }
  }
  /*
  Relação de codigos de erro:
  Código	tipo	Descrição
  100	DefaultError	Ops! Algo inesperado aconteceu
  101	PermissionError	Usuário negou permissão de acesso a câmera
  102	SessionError	Timeout, sessão expirada por tempo máximo para captura excedido
  103	SessionError	Timeout, sessão expirada por inatividade
  104	LifecicleError	Captura encerrada por estar com orientação no modo LANDSCAPE
  105	LifecicleError	Captura encerrada por inatividade de tela
  106	LifecicleError	Captura encerrada pelo usuário desligar o display
  107	ImplementationError	O elemento com id #box-camera não foi encontrado
  108	ImplementationError	Tipo de câmera inválido
  109	ModelError	Modelos não foram carregados previamente
  110	ImplementationError	Métodos de Callback não implementados corretamente
  111	ModelError	Não foi possível baixar os modelos, pode ser erro de diretório
  112	SupportError	Browser não suportado
  114	PermissionError	Câmera ocupada por outra aplicação
  */

  onFailedCapture(err) {
    const msg = `Código de erro: ${err.code} - ${err.message}`;
    this.showError(msg);

    this.errorEmitter.emit(err);
    console.log(err);
  }

  onBrowserNotSupport(obj) {
    this.showError(obj.message);
    this.showCustonMessage = false;
    this.errorEmitter.emit(obj);
    console.log(obj);
  }

  public backSignature(): void {
    this.showCustonMessage = false;
    this.biometryService.setBiometryStage(BiometryStageEnum.INSTRUCTIONS);
    this.goBack.emit();
  }

  ngOnDestroy() {
    this.biometryManagerService.removeJsFromElement('platform', this.renderer);
    this.biometryManagerService.removeJsFromElement('adapter_latest', this.renderer);
    this.biometryManagerService.removeJsFromElement('jszip', this.renderer);
    this.biometryManagerService.removeJsFromElement('stegger', this.renderer);
  }
}
