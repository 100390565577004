import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as proposalInformationSelectors from '@app/core/state/proposal-information/proposal-information.selectors';
import * as proposalActions from '@app/core/state/proposal/proposal.actions';
import * as proposalSelectors from '@app/core/state/proposal/proposal.selectors';
import { PendencyService } from '@app/pendency/pendency.service';
import { PostAgentLegalDTORequest } from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/models/api/post.agent-legal.model';
import {
  BiometryResponseEnum,
  PostBiometryDTORequest
} from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/models/api/post.biometry.model';
import {
  PostSignatureDTORequest,
  PostSignatureDTOResponse,
  SignatureStatusEnum
} from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/models/api/post.signature.model';
import { CrossedFluxLogoutReasonsEnum } from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/models/biometry.model';
import {
  BiometryService,
  BiometryStageEnum
} from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/services/biometry.service';
import { ChecklistItemDTO } from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/api/query.checklist.model';
import { ChecklistModel } from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/checklist.model';
import { FormalizationService } from '@app/proposal-workflow/containers/step-formalization/services/formalization.service';
import { FormalizationStatusEnum } from '@app/proposal-workflow/models/formalization-status.enum';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { TaggerService } from '@app/tagging/tagger.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { UnicoService } from '@shared/services/unico/unico.service';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '..';
import * as actions from './formalization.actions';
import * as formalizationSelectors from './formalization.selectors';

const mapChecklistItem = (
  checklistItem: ChecklistItemDTO,
  proposalId: string,
  channelSupplier: { id: string; code?: number; description?: string }
): ChecklistModel => {
  const {
    checklistId: id,
    client,
    type,
    artifact,
    document: { id: code, code: docCode, name, description, allowedTypes, maxImages: maxAmount, integrationTypes },
    uploadGroup,
    encrypted,
    status
  } = checklistItem;

  const result: ChecklistModel = {
    id,
    client,
    proposal: { id: +proposalId, channelSupplier },
    type,
    artifact,
    status,
    document: {
      code,
      docCode,
      name,
      description,
      maxAmount,
      allowedTypes,
      integrationTypes
    },
    encrypted,
    uploadGroup
  };
  return result;
};

@Injectable()
export class FormalizationEffects {
  constructor(
    private actions$: Actions,
    public dialog: MatDialog,
    private store$: Store<AppState>,
    private formalizationService: FormalizationService,
    private biometryService: BiometryService,
    private translateService: TranslateService,
    private pendencyService: PendencyService,
    private unicoService: UnicoService,
    private taggerService: TaggerService
  ) { }

  @Effect()
  sendAgentLegalAction = this.actions$.pipe(
    ofType<actions.SendAgentLegalAction>(actions.FormalizationActionTypes.SEND_AGENT_LEGAL),
    withLatestFrom(this.store$.select(proposalInformationSelectors.getConfirmationData)),
    switchMap(([action, proposalState]) => {
      const { channelSupplier, proposalData } = proposalState;
      const isReturningToEdit = action.payload.isReturningToEdit;

      let payload: PostAgentLegalDTORequest = {
        proposal: {
          id: +proposalData.id,
          channelSupplier: {
            id: channelSupplier.id,
            code: channelSupplier.code,
            name: channelSupplier.name
          },
          product: {
            code: proposalData.product as ProductCodeEnum,
            name: proposalData.product
          }
        },
        person: action.payload.agentsLegal
      };

      if (isReturningToEdit) {
        const mappedPerson = action.payload.agentsLegal.map(agent => {
          const { id, email, phoneDDD, phoneNumber } = agent;
          return {
            id,
            email,
            phoneDDD,
            phoneNumber
          };
        });

        payload = { ...payload, person: mappedPerson };
      }

      return this.biometryService.sendAgentLegal(payload, action.payload.isReturningToEdit).pipe(
        switchMap(res => of(new actions.SendAgentLegalSuccessAction(isReturningToEdit ? payload.person : res.person))),
        catchError(err => of(new actions.SendAgentLegalErrorAction(err)))
      );
    })
  );

  @Effect()
  sendAgentLegalSuccessAction = this.actions$.pipe(
    ofType<actions.SendAgentLegalSuccessAction>(actions.FormalizationActionTypes.SEND_AGENT_LEGAL_SUCCESS),
    withLatestFrom(this.store$.select(proposalSelectors.selectProposal)),
    switchMap(([action, proposalState]) => {
      const { id } = proposalState.data;
      return of(new proposalActions.GetChecklistAction({ proposalId: id, isPendency: false }));
    })
  );

  @Effect()
  getCrossedFluxLinkAction = this.actions$.pipe(
    ofType<actions.GetCrossedFluxLinkAction>(actions.FormalizationActionTypes.GET_CROSSED_FLUX_LINK),
    switchMap(action =>
      this.biometryService.getCrossedFluxLink(action.payload).pipe(
        switchMap(res => of(new actions.GetCrossedFluxLinkSuccessAction(res))),
        catchError(err => of(new actions.GetCrossedFluxLinkErrorAction(err)))
      )
    )
  );

  @Effect()
  getCrossedFluxStatusAction = this.actions$.pipe(
    ofType<actions.GetCrossedFluxStatusAction>(actions.FormalizationActionTypes.GET_CROSSED_FLUX_STATUS),
    switchMap(action =>
      this.biometryService.getCrossedFluxStatus(action.payload.uuid).pipe(
        switchMap(res => of(new actions.GetCrossedFluxStatusSuccessAction(res))),
        catchError(err => of(new actions.GetCrossedFluxStatusErrorAction(err)))
      )
    )
  );

  @Effect()
  getCrossedFluxInfoAction = this.actions$.pipe(
    ofType<actions.GetCrossedFluxInfoAction>(actions.FormalizationActionTypes.GET_CROSSED_FLUX_INFO),
    switchMap(action =>
      this.biometryService.getCrossedFluxInfo(action.payload.uuid, action.payload.uuidValidate).pipe(
        map(res => {
          const channelSupplier = {
            id: res.proposal.channelSupplierId
          };
          const mappedChecklist = res.checklists.map(item => mapChecklistItem(item, res.proposal.id, channelSupplier));
          return { ...res, checklists: mappedChecklist };
        }),
        switchMap(res => of(
          new actions.GetCrossedFluxInfoSuccessAction(res),
          new proposalActions.SetProposalIdAction(res.proposal.id)
        )),
        catchError(err => of(new actions.GetCrossedFluxInfoErrorAction(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  getCrossedFluxInfoErrorAction = this.actions$.pipe(
    ofType<actions.GetCrossedFluxInfoErrorAction>(actions.FormalizationActionTypes.GET_CROSSED_FLUX_INFO_ERROR),
    map(action => this.biometryService.crossedFluxLogout(CrossedFluxLogoutReasonsEnum.INVALID_QR))
  );

  @Effect()
  checkBiometryStatusAction = this.actions$.pipe(
    ofType<actions.CheckBiometryStatusAction>(actions.FormalizationActionTypes.CHECK_BIOMETRY_STATUS),
    switchMap(action =>
      this.biometryService.checkBiometryStatus(action.payload).pipe(
        switchMap(res => of(new actions.CheckBiometryStatusSuccessAction(res))),
        catchError(err => of(new actions.CheckBiometryStatusErrorAction(err)))
      )
    )
  );

  @Effect()
  checkBiometryCrossFluxStatusAction = this.actions$.pipe(
    ofType<actions.CheckBiometryCrossFluxStatusAction>(
      actions.FormalizationActionTypes.CHECK_BIOMETRY_CROSS_FLUX_STATUS
    ),
    switchMap(action =>
      this.biometryService.getBiometryCrossedFluxStatus(action.payload).pipe(
        switchMap(res => of(new actions.CheckBiometryCrossFluxStatusSuccessAction(res.status))),
        catchError(err => of(new actions.CheckBiometryCrossFluxStatusErrorAction(err)))
      )
    )
  );

  @Effect()
  sendBiometryUploadAction = this.actions$.pipe(
    ofType<actions.SendBiometryUploadAction>(actions.FormalizationActionTypes.SEND_BIOMETRY_UPLOAD),
    withLatestFrom(
      this.store$.select(formalizationSelectors.selectBiometry),
      this.store$.select(formalizationSelectors.selectCrossedFluxUuid)
    ),
    filter(([_, biometry]) => !!biometry.files && biometry.files.filter(f => f.data && f.data !== '/').length > 0),
    switchMap(([action, biometry, crossedFluxUuid]) => {
      const { customer, channelSupplier } = action.payload as FormalizationProposalDTO;
      const biometryObj = biometry;
      const biometryPayload: PostBiometryDTORequest = {
        id: +biometryObj.id,
        proposal: {
          id: biometryObj.proposal.id,
          supplierChannel: {
            id: +channelSupplier.id
          }
        },
        client: {
          id: +customer.id,
          name: customer.name,
          email: customer.email,
          documentNumber: customer.documentNumber
        },
        encryptedUnicoJwt: biometry.encrypted,
        document: biometryObj.document,
        type: biometryObj.type,
        files: biometryObj.files
      };
      const uuid = crossedFluxUuid || biometryObj.proposal.id.toString();

      return this.biometryService.postBiometryUpload(biometryPayload, uuid).pipe(
        map(() => {
          return new actions.SendBiometryAction({
            ...biometryPayload,
            imageId: uuid,
            encryptedUnicoJwt: biometry.encrypted,
            files: undefined
          });
        }),
        catchError(() => of(new actions.SendBiometryRemakeAction({ status: BiometryResponseEnum.REMAKE })))
      );
    })
  );

  @Effect()
  sendBiometryAction = this.actions$.pipe(
    ofType<actions.SendBiometryAction>(actions.FormalizationActionTypes.SEND_BIOMETRY),
    switchMap(action => {
      return this.biometryService.postBiometry(action.payload).pipe(
        map(res => {
          if (res.status === BiometryResponseEnum.PENDING) {
            return new actions.SendBiometryPendingAction(res);
          }

          if (res.status === BiometryResponseEnum.CANCELLED) {
            return new actions.SendBiometryErrorAction(res);
          }

          if (res.status === BiometryResponseEnum.REMAKE) {
            return new actions.SendBiometryRemakeAction(res);
          }

          if (res.status === BiometryResponseEnum.APPROVED) {
            return new actions.SendBiometrySuccessAction(res);
          }
        }),
        catchError(err => of(new actions.SendBiometryRemakeAction({ status: BiometryResponseEnum.REMAKE })))
      );
    })
  );

  @Effect({ dispatch: false })
  sendBiometrySuccessAction = this.actions$.pipe(
    ofType<actions.SendBiometrySuccessAction>(actions.FormalizationActionTypes.SEND_BIOMETRY_SUCCESS),
    map(action => this.biometryService.setBiometryStage(BiometryStageEnum.DONE))
  );

  @Effect({ dispatch: false })
  sendBiometryPendingAction = this.actions$.pipe(
    ofType<actions.SendBiometryPendingAction>(actions.FormalizationActionTypes.SEND_BIOMETRY_PENDING),
    map(action => this.biometryService.setBiometryStage(BiometryStageEnum.PENDING))
  );

  @Effect({ dispatch: false })
  sendBiometryRemakeAction = this.actions$.pipe(
    ofType<actions.SendBiometryRemakeAction>(actions.FormalizationActionTypes.SEND_BIOMETRY_REMAKE),
    map(action => this.biometryService.setBiometryStage(BiometryStageEnum.INSTRUCTIONS))
  );

  @Effect()
  sendBiometryErrorAction = this.actions$.pipe(
    ofType<actions.SendBiometryErrorAction>(actions.FormalizationActionTypes.SEND_BIOMETRY_ERROR),
    map(action => {
      this.biometryService.setBiometryStage(BiometryStageEnum.BIOMETRY_STATUS);
      return new actions.AbortFormalizationAction();
    })
  );

  @Effect()
  sendSignatureAction = this.actions$.pipe(
    ofType<actions.SendSignatureAction>(actions.FormalizationActionTypes.SEND_SIGNATURE),
    withLatestFrom(this.store$.select(formalizationSelectors.selectBiometry)),
    switchMap(([action, signature]) => {
      const { customer, channelSupplier } = action.payload as FormalizationProposalDTO;
      const signatureChecklist = signature as ChecklistModel;
      const signaturePayload: PostSignatureDTORequest = {
        id: signatureChecklist.id,
        proposal: {
          id: signatureChecklist.proposal.id,
          supplierChannel: {
            id: channelSupplier.id
          }
        },
        client: {
          id: +customer.id,
          name: customer.name,
          email: customer.email,
          documentNumber: customer.documentNumber
        },
        document: signatureChecklist.document,
        type: signatureChecklist.type,
        files: signatureChecklist.files
      };

      return this.biometryService.sendSignature(signaturePayload).pipe(
        map((res: PostSignatureDTOResponse) => {
          if (res.status === SignatureStatusEnum.SIGNED) {
            return new actions.SendSignatureSuccessAction(res);
          }

          if (res.status === SignatureStatusEnum.REMAKE) {
            return new actions.SendSignatureRemakeAction(res);
          }
        }),
        catchError(err => of(new actions.SendSignatureRemakeAction({ status: SignatureStatusEnum.REMAKE })))
      );
    })
  );

  @Effect({ dispatch: false })
  sendSignatureSuccessAction = this.actions$.pipe(
    ofType<actions.SendSignatureSuccessAction>(actions.FormalizationActionTypes.SEND_SIGNATURE_SUCCESS),
    map(action => this.biometryService.setBiometryStage(BiometryStageEnum.DONE))
  );

  @Effect({ dispatch: false })
  sendSignatureRemakeAction = this.actions$.pipe(
    ofType<actions.SendSignatureRemakeAction>(actions.FormalizationActionTypes.SEND_SIGNATURE_REMAKE),
    map(action => this.biometryService.setBiometryStage(BiometryStageEnum.INSTRUCTIONS))
  );

  @Effect()
  sendSignatureErrorAction = this.actions$.pipe(
    ofType<actions.SendSignatureErrorAction>(actions.FormalizationActionTypes.SEND_SIGNATURE_ERROR),
    map(action => {
      this.biometryService.setBiometryStage(BiometryStageEnum.INSTRUCTIONS);
      return new actions.AbortFormalizationAction();
    })
  );

  @Effect()
  getMaxFileSizeAllowedAction = this.actions$.pipe(
    ofType<actions.GetMaxFileSizeAllowedAction>(actions.FormalizationActionTypes.GET_MAX_FILE_SIZE_ALLOWED),
    switchMap(() =>
      this.formalizationService.getMaxFileSizeAllowed().pipe(
        switchMap(res => of(new actions.GetMaxFileSizeAllowedSuccessAction(res))),
        catchError(err => of(new actions.GetMaxFileSizeAllowedErrorAction(err)))
      )
    )
  );

  @Effect()
  getSendTypesAction = this.actions$.pipe(
    ofType<actions.GetSendTypesAction>(actions.FormalizationActionTypes.GET_SEND_TYPES),
    switchMap(() =>
      this.formalizationService.getSendType().pipe(
        switchMap(res => of(new actions.GetSendTypesSuccessAction(res))),
        catchError(err => of(new actions.GetSendTypesErrorAction(err)))
      )
    )
  );

  @Effect()
  submitFormalizationAction = this.actions$.pipe(
    ofType<actions.SubmitFormalizationAction>(actions.FormalizationActionTypes.SUBMIT_FORMALIZATION),
    switchMap(action =>
      this.formalizationService.finishFormalization(action.payload).pipe(
        switchMap(res => of(new actions.SubmitFormalizationSuccessAction(res))),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 400) {
            const _description = err?.error[0]?.businessMessage;
            this.GenerateModalDialog(null, _description);
          }

          return of(new actions.SubmitFormalizationErrorAction(err));
        })
      )
    )
  );

  @Effect()
  submitFormalizationSuccessAction = this.actions$.pipe(
    ofType<actions.SubmitFormalizationSuccessAction>(actions.FormalizationActionTypes.SUBMIT_FORMALIZATION_SUCCESS),
    map(action => new actions.SetFormalizationStatusAction(FormalizationStatusEnum.FINISHED))
  );

  @Effect()
  submitFormalizationErrorAction = this.actions$.pipe(
    ofType<actions.SubmitFormalizationErrorAction>(actions.FormalizationActionTypes.SUBMIT_FORMALIZATION_ERROR),
    map(action => new actions.SetFormalizationStatusAction(FormalizationStatusEnum.PENDING))
  );

  @Effect()
  getTutorialSteps = this.actions$.pipe(
    ofType<actions.GetTutorialSteps>(actions.FormalizationActionTypes.GET_TUTORIAL_STEPS),
    switchMap(action =>
      this.biometryService.getBiometryTutorialSteps().pipe(
        switchMap(res => of(new actions.GetTutorialStepsSuccess(res))),
        catchError(err => of(new actions.GetTutorialStepsError(err)))
      )
    )
  );

  @Effect()
  getSummarySteps = this.actions$.pipe(
    ofType<actions.GetSummarySteps>(actions.FormalizationActionTypes.GET_SUMMARY_STEPS),
    switchMap(action => {
      if (action.payload.isPendency) {
        return this.pendencyService.getSuccessSummarySteps().pipe(
          switchMap(res => of(new actions.GetSummaryStepsSuccess(res))),
          catchError(err => of(new actions.GetSummaryStepsError(err)))
        );
      }

      return this.formalizationService
        .getSummarySteps(action.payload.productCode, action.payload.stageCode, action.payload.checklists)
        .pipe(
          switchMap(res => of(new actions.GetSummaryStepsSuccess(res))),
          catchError(err => of(new actions.GetSummaryStepsError(err)))
        );
    })
  );

  @Effect()
  getSummaryStatus = this.actions$.pipe(
    ofType<actions.GetSummaryStatus>(actions.FormalizationActionTypes.GET_SUMMARY_STATUS),
    filter(action => !!action.payload),
    switchMap(action =>
      this.formalizationService.getSummaryStatus(action.payload).pipe(
        switchMap(res => of(new actions.GetSummaryStatusSuccess(res))),
        catchError(err => of(new actions.GetSummaryStatusError(err)))
      )
    )
  );

  @Effect()
  getIvNonce = this.actions$.pipe(
    ofType<actions.GetIvNonceAction>(actions.FormalizationActionTypes.GET_IV_NONCE),
    switchMap(action =>
      this.biometryService.getIvNonce(action.payload).pipe(
        switchMap(res => of(new actions.GetIvNonceSuccessAction(res))),
        catchError(err => of(new actions.GetIvNonceErrorAction(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  abortFormalization = this.actions$.pipe(
    ofType<actions.AbortFormalizationAction>(actions.FormalizationActionTypes.ABORT_FORMALIZATION),
    map(action => this.biometryService.setBiometryStage(BiometryStageEnum.BIOMETRY_STATUS))
  );


  GenerateModalDialog(
    title: string = this.translateService.instant('GENERIC-SIMULATION-ERROR-MODAL-TITLE'),
    message: string = this.translateService.instant('GENERIC-SIMULATION-ERROR-MODAL-DESCRIPTION')
  ): void {
    this.dialog.open(PionModalComponent, {
      data: {
        title: title,
        description: message,
        confirmText: 'OK',
        cancelText: null,
        type: 'alert'
      },
      maxWidth: '500px'
    });
  }


  @Effect({ dispatch: false })
  GetCrossedFluxInfoSuccessEffect = this.actions$.pipe(
    ofType<actions.GetCrossedFluxInfoSuccessAction>(actions.FormalizationActionTypes.GET_CROSSED_FLUX_INFO_SUCCESS),
    map((action) => this.taggerService.setProposalType(action.payload?.proposal?.product?.code))
  );
}
