export interface CertifiedAgentModel {
  agentName?: string;
  documentNumber?: string;
  agentCertifiedNumber?: string;
  expirationDate?: string;
  relationshipDate?: string;
  certificationNumber?: string;
  deadlineCertificateDay?: string;
  validDaysCetification?: number;
  daysEndLink?: number;
}

export interface AgentCertified {
  agentCertified?: Array<CertifiedAgentModel>;
}

export enum CertifiedAgentResponseErrorEnum {
  EAC001 = 'EAC001',
  EAC002 = 'EAC002',
  EAC003 = 'EAC003',
  EAC004 = 'EAC004',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  DEFAULT = 'DEFAULT'
}
